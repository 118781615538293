@import "variables";
@import 'bootstrap/bootstrap';

.typed {
    color: #006e29;
}

.blog-tags span.badge-primary {
    color: white;
}

.image-wrapper { 
    text-align:center
}

.image-wrapper .image-caption { 
    color: #818080;
    margin-top: 10px
}
