$spacing-unit: 30px;
$rounded-borders: 15px;

$primary: #77b753;
$enable-gradients: true;

$carousel-control-color: #77b753;
$carousel-caption-color: #77b753;
$carousel-indicator-active-bg: #77b753;

$card-columns-count: 4;